import style from './TopButton.module.css';

export function TopButton({ onClick, active }) {
  /*
   * properties
   */

  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
      <button aria-label="scroll to top" className={`${style.button} ${active ? style.active : ''}`} onClick={onClick}>
        <div className={`${style.bar} ${style.bar1} ${style.top}`} />
        <div className={`${style.bar} ${style.bar2} ${style.top}`} />
        <div className={`${style.bar} ${style.bar1} ${style.bottom}`} />
      </button>
    </>
  );
}
