import { useRef } from 'react';
import { useMainScroll } from '../../../../stores/useMainScroll';
import { useFrame } from '@react-three/fiber';
import { Carousel } from './Carousel';

export function Team() {
  /*
   * properties
   */

  const group = useRef();
  const getSectionPct = useMainScroll((state) => state.getSectionPct);

  /*
   * hooks
   */

  useFrame(() => {
    let pct = getSectionPct(3);

    const active = pct > 0 && pct < 1;

    if (group.current.visible != active) {
      group.current.visible = active;
    }
  });

  /*
   * visuals
   */

  return (
    <>
      <group ref={group} visible={false}>
        <Carousel />
      </group>
    </>
  );
}
