import './style.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { StrictMode, Suspense } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { App3D } from './app3D/App3D';
import { App2D } from './app2D/App2D';
import { MouseController } from './controllers/MouseController';
import smoothscroll from 'smoothscroll-polyfill';
import { Preloader } from './app2D/component/preloader/Preloader';
import './i18n';

const history = createBrowserHistory({ window });
const root = ReactDOM.createRoot(document.querySelector('#root'));

smoothscroll.polyfill();

root.render(
  <StrictMode>
    <BrowserRouter history={history}>
      <Preloader />

      <MouseController />

      <App2D />

      <Suspense>
        <App3D />
      </Suspense>
    </BrowserRouter>
  </StrictMode>
);

function BrowserRouter({ children, history }) {
  let [state, dispatch] = React.useReducer((_, action) => action, {
    action: history.action,
    location: history.location,
  });

  React.useLayoutEffect(() => history.listen(dispatch), [history]);

  return <Router children={children} action={state.action} location={state.location} navigator={history} />;
}
