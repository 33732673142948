import { useEffect, useRef, useState } from 'react';
import style from './About.module.css';
import { useMainScroll } from '../../../../stores/useMainScroll';
import { MathUtils } from 'three';
import { Content } from '../../../component/content/Content';
import { useTranslation } from 'react-i18next';

export function About() {
  /*
   * properties
   */

  const { t } = useTranslation('translation', { keyPrefix: 'about' });

  const paragraph = useRef();
  const getSectionPct = useMainScroll((state) => state.getSectionPct);
  const [isVisible, setVisible] = useState(false);

  /*
   * hooks
   */

  useEffect(() => {
    loop();
  }, []);

  const loop = () => {
    let pct = getSectionPct(2);

    animate(pct);

    requestAnimationFrame(loop);
  };

  const animate = (pct) => {
    const visible = pct > 0 && pct < 1;
    setVisible(visible);

    if (visible) {
      const start = MathUtils.mapLinear(pct, 0, 0.1, 0, 1);
      const end = MathUtils.mapLinear(pct, 0.9, 1, 1, 0);
      const num = Math.min(start, end, 1);

      paragraph.current.style.opacity = num;
      paragraph.current.style.transform = `translateY(${(1 - num) * 10}px)`;
    }
  };

  /*
   * visuals
   */

  return (
    <>
      <Content isVisible={isVisible}>
        <div className={style.container}>
          <p ref={paragraph} className={style.body}>
            {t('body')}
          </p>
        </div>
      </Content>
    </>
  );
}
