import style from './Icon.module.css';

import Arrow from './svg/arrow.svg?react';
import World from './svg/world.svg?react';

export function Icon({ svg = 'arrow' }) {
  /*
   * properties
   */

  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
      {svg === 'arrow' && <Arrow />}
      {svg === 'world' && <World />}
    </>
  );
}
