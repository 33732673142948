import { create } from 'zustand';

export const useMainScroll = create((set, get) => ({
  pct: 0,
  activeSection: 0,
  sections: 6,
  scrollToSection: -1,
  // scrollToTop: null,

  setPct: (pct) => set({ pct }),
  setActiveSection: (activeSection) => set({ activeSection }),

  getSectionPct: (section) => {
    return Math.max(0, Math.min(1, get().pct * get().sections - (section - 1)));
  },

  // setScrollToTop: (scrollToTop) => set({ scrollToTop }),
  setScrollToSection: (scrollToSection) => set({ scrollToSection }),
  resetScrollToSection: () => set({ scrollToSection: -1 }),
}));
