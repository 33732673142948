import style from './StandardButton.module.css';

export function StandardButton({ children, onClick, disabled = false }) {
  /*
   * properties
   */

  /*
   * hooks
   */


  const clickHandler = (e) => {
    onClick && onClick(e);
  };

  /*
   * visuals
   */

  return (
    <>
      <button
        disabled={disabled}
        className={style.button}
        onClick={clickHandler}
      >
        {children}
      </button>
    </>
  );
}
