import { About } from "./about/About";
import { Collection } from "./collection/Collection";
import { Community } from "./community/Community";
import { Intro } from "./intro/Intro";
import { Soon } from "./soon/Soon";
import { Team } from "./team/Team";
import { Timeline } from "./timeline/Timeline";
import { Bear } from "./bear/Bear";

export function Home() {
  /*
   * properties
   */

  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
      <Intro />
      <About />
      <Team />
      {/* <Timeline /> */}
      <Bear />
      <Collection />
      <Community />
      {/* <Soon /> */}
    </>
  );
}
