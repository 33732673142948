import { useEffect, useRef, useState } from 'react';
import style from './LanguageSelect.module.css';
import { MenuButton } from './MenuButton';
import { BigButton } from './BigButton';
import { StandardButton } from '../buttons/StandardButton';
import { Icon } from '../Icon';
import gsap from 'gsap';
import i18n from 'i18next';

export function LanguageSelect() {
  /*
   * properties
   */

  const [showMenu, setShowMenu] = useState(false);
  const menu = useRef();
  const [lang, setLang] = useState('en');

  /*
   * hooks
   */

  useEffect(() => {
    document.addEventListener('click', bgHandler);

    return () => {
      document.removeEventListener('click', bgHandler);
    };
  }, []);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  const toggle = (e) => {
    // console.log('toggle');
    e.stopPropagation();
    setShowMenu(!showMenu);
  };

  const bgHandler = (e) => {
    // console.log('bgHandler');
    setShowMenu(false);
  };

  useEffect(() => {
    gsap.killTweensOf(menu.current);
    if (showMenu) {
      gsap.set(menu.current, { y: -10 });
      gsap.to(menu.current, { autoAlpha: 1, y: 0, duration: 0.4, ease: 'power4.out' });
    } else {
      gsap.to(menu.current, { autoAlpha: 0, duration: 0.4, ease: 'power4.out' });
    }
  }, [showMenu]);

  /*
   * visuals
   */

  return (
    <>
      <div className={style.languageSelect} onPointerCancel={toggle}>
        <StandardButton onClick={toggle}>
          <Icon svg="world" />
          {lang.toUpperCase()}
        </StandardButton>

        {/* {showMenu && ( */}
        <div ref={menu} className={style.menu}>
          <button onClick={() => setLang('en')} className={style.button}>
            English
          </button>
          <button onClick={() => setLang('jp')} className={style.button}>
            日本語
          </button>
        </div>
        {/* )} */}
      </div>
    </>
  );
}
