import { useEffect, useRef } from 'react';
import { RoundButton } from '../buttons/RoundButton';
import style from './FixedFooter.module.css';
import { TopButton } from './TopButton';
import gsap from 'gsap';
import { useMainScroll } from '../../../stores/useMainScroll';
import { useSections } from '../../../stores/useSections';

export function FixedFooter() {
  /*
   * properties
   */

  const container = useRef();
  // const scrollToTop = useMainScroll((state) => state.scrollToTop);
  const loaded = useSections((state) => state.loaded);
  const setScrollToSection = useMainScroll((state) => state.setScrollToSection);

  /*
   * hooks
   */

  useEffect(() => {
    if (loaded) {
      show();
    }
  }, [loaded]);

  const show = () => {
    gsap.set(container.current, { y: 15 });
    gsap.to(container.current, { delay: 1, duration: 2, opacity: 1, y: 0, ease: 'power4.inOut' });
  };

  const clickHandler = () => {
    // console.log(scrollToTop);
    // scrollToTop();
    setScrollToSection(0);
  };

  /*
   * visuals
   */

  return (
    <>
      <div ref={container} className={style.footer}>
        <TopButton onClick={clickHandler} />
      </div>
    </>
  );
}
