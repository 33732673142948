import { useEffect, useRef } from 'react';
import { useMainScroll } from '../../../stores/useMainScroll';
import { Icon } from '../Icon';
import style from './ScrollIndicator.module.css';
import gsap from 'gsap';
import { useSections } from '../../../stores/useSections';
import { useTranslation } from 'react-i18next';

let shown = true;

export function ScrollIndicator() {
  /*
   * properties
   */

  const { t } = useTranslation('translation', { keyPrefix: 'scroll' });

  const container = useRef();
  const inner = useRef();
  const loaded = useSections((state) => state.loaded);

  /*
   * hooks
   */

  useEffect(() => {
    if (loaded) {
      show();
    }
  }, [loaded]);

  const show = () => {
    const intervalId = setInterval(checkScroll, 100);

    gsap.set(inner.current, { y: 10 });
    gsap.to(inner.current, { delay: 1.7, opacity: 1, y: 0, duration: 1, ease: 'power4.in' });

    return () => {
      clearInterval(intervalId);
    };
  };

  const checkScroll = () => {
    const pct = useMainScroll.getState().pct;

    if (pct <= 0.05 && !shown) {
      shown = true;

      gsap.killTweensOf(container.current);
      gsap.to(container.current, { opacity: 1, y: 0, duration: 0.5, ease: 'power2.out' });
    } else if (pct > 0.05 && shown) {
      shown = false;

      gsap.killTweensOf(container.current);
      gsap.to(container.current, { opacity: 0, y: 5, duration: 0.5, ease: 'power2.out' });
    }
  };

  /*
   * visuals
   */

  return (
    <>
      <div ref={container} className={style.indicator}>
        <div ref={inner} className={style.inner}>
          <span className={style.arrow}>▾</span> {t('body')} <span className={style.arrow}>▾</span>
        </div>
      </div>
    </>
  );
}
