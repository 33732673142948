import style from './Content.module.css';

export function Content({ isVisible, children }) {
  /*
   * properties
   */

  /*
   * visuals
   */

  return (
    <>
      <div className={`${style.container} ${isVisible ? style.visible : ''}`}>
        {children}
      </div>
    </>
  );
}
