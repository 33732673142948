import { create } from 'zustand';

export const useMaps = create((set) => ({
  loaded: false,

  maps: {},

  init: (maps) =>
    set({
      loaded: true,

      maps: {
        home: maps[0],
        homeDepth: maps[1],

        about: maps[2],
        aboutDepth: maps[3],

        team1: maps[4],
        team1Depth: maps[5],
        team2: maps[6],
        team2Depth: maps[7],
        team3: maps[8],
        team3Depth: maps[9],
        team4: maps[10],
        team4Depth: maps[11],
        team5: maps[12],
        team5Depth: maps[13],
      },
    }),
}));
