import { useEffect, useRef } from 'react';
import style from './Intro.module.css';
import gsap from 'gsap';
import { useMainScroll } from '../../../../stores/useMainScroll';
import { useSections } from '../../../../stores/useSections';

let isVisible = false;

export function Intro() {
  /*
   * properties
   */

  const header = useRef();
  const getSectionPct = useMainScroll((state) => state.getSectionPct);
  const loaded = useSections((state) => state.loaded);

  /*
   * hooks
   */

  useEffect(() => {
    loop();
  }, []);

  useEffect(() => {
    if (loaded) {
      show();
    }
  }, [loaded]);

  const show = () => {
    // gsap.set(header.current, { scaleY: 0 });
    gsap.to(header.current, { delay: 1, scaleY: 1, duration: 0.75, ease: 'power1.out' });

    gsap.to(header.current.children, { delay: .8, y: 0, duration: 2, stagger: 0.1, ease: 'power4.inOut' });
  };

  const loop = () => {
    let pct = getSectionPct(1);

    setVisible(pct);

    if (isVisible) {
      animate(pct);
    }

    requestAnimationFrame(loop);
  };

  const animate = (pct) => {
    pct = Math.pow(pct, 8);
    gsap.set(header.current, { opacity: 1 - pct, y: pct * 20, letterSpacing: 0.1 + pct * 0.2 + 'em' });
  };

  const setVisible = (pct) => {
    const visible = pct >= 0 && pct < 1;
    if (isVisible !== visible) {
      isVisible = visible;

      header.current.style.visibility = isVisible ? 'visible' : 'hidden';
    }
  };

  /*
   * visuals
   */

  return (
    <>
      <h1 ref={header} className={style.header}>
        {'REGASHI'.split('').map((value, index) => (
          <span key={index}>{value}</span>
        ))}
      </h1>
    </>
  );
}
