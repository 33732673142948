import { useEffect, useRef } from 'react';
import { useMouse } from '../../../../stores/useMouse';
import { Mask, useMask, useTexture } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useMainScroll } from '../../../../stores/useMainScroll';
import { MathUtils } from 'three';
import * as THREE from 'three';
import { useMaps } from '../../../../stores/useMaps';

export function MaskedPicture({ map, displacementMap, maskId }) {
  /*
   * properties
   */

  const stencil = useMask(maskId);

  const mesh = useRef();
  const mask = useRef();

  const mapTexture = useMaps.getState().maps[map];
  // const mapTexture = useMaps.getState()[displacementMap];
  const displacementMapTexture = useMaps.getState().maps[displacementMap];

  const getSectionPct = useMainScroll((state) => state.getSectionPct);

  /*
   * hooks
   */

  useFrame(() => {
    setMask();
    setMouse();
  });

  const setMask = () => {
    let pct = getSectionPct(3);

    if (pct > 0 && pct < 1) {
      const start = MathUtils.mapLinear(pct, 0, 0.1, 0, 1);
      const end = MathUtils.mapLinear(pct, 0.9, 1, 1, 0);
      let scale = MathUtils.clamp(Math.min(start, end), 0, 1);
      const scaleX = Math.pow(scale, 0.4);
      const scaleY = Math.pow(scale, 2);
      mask.current.scale.set(scaleX, scaleY, 1);
    }
  };

  const setMouse = () => {
    const x = useMouse.getState().slowPctX;
    const y = useMouse.getState().slowPctY;

    mesh.current.rotation.y = -x * 0.1;
    mesh.current.rotation.x = -y * 0.1;
  };

  /*
   * visuals
   */

  return (
    <>
      <Mask ref={mask} id={maskId}>
        <planeGeometry args={[1, 1, 1, 1]} />
        <meshBasicMaterial />
      </Mask>

      <group ref={mesh}>
        {/* <mesh position={[0, 0, -0.35]}>
          <planeGeometry args={[1.4, 1.4, 1, 1]} />
          <meshBasicMaterial transparent={true} opacity={0.5} color="#02193f" depthWrite={false} {...stencil} />
        </mesh> */}

        <mesh position={[0, 0, -0.41]}>
          <planeGeometry args={[1.6, 1.6, 1, 1]} />
          <meshBasicMaterial transparent={true} opacity={0.5} color="#02193f" depthWrite={false} {...stencil} />
        </mesh>

        <mesh position={[0, 0, -0.4]}>
          <planeGeometry args={[1.2, 1.2, 30, 30]} />
          <meshMatcapMaterial
            map={mapTexture}
            displacementMap={displacementMapTexture}
            displacementScale={0.11}
            {...stencil}
          />
        </mesh>
      </group>
    </>
  );
}
