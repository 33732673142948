import { useEffect, useRef, useState } from 'react';
import style from './Header.module.css';
import { MenuButton } from './MenuButton';
import { BigButton } from './BigButton';
import { StandardButton } from '../buttons/StandardButton';
import { Icon } from '../Icon';
import gsap from 'gsap';
import { LanguageSelect } from './LanguageSelect';
import { useSections } from '../../../stores/useSections';
import { useMainScroll } from '../../../stores/useMainScroll';
import { useTranslation } from 'react-i18next';

export function Header() {
  /*
   * properties
   */
  const { t } = useTranslation('translation', { keyPrefix: 'header' });

  const [active, setActive] = useState(false);
  const loaded = useSections((state) => state.loaded);
  const setScrollToSection = useMainScroll((state) => state.setScrollToSection);

  const background = useRef();
  const navigation = useRef();
  const header = useRef();

  /*
   * hooks
   */

  useEffect(() => {
    if (loaded) {
      begin();
    }
  }, [loaded]);

  const begin = () => {
    gsap.set(background.current, { scaleY: 0 });

    gsap.set(header.current, { y: -15 });
    gsap.to(header.current, { delay: 1, duration: 2, opacity: 1, y: 0, ease: 'power4.out' });
  };

  const bigButtonHandler = (index) => {
    setActive(false);
    hide();
    setScrollToSection(index);
  };

  const menuButtonHandler = () => {
    gsap.killTweensOf(background.current);
    gsap.killTweensOf(navigation.current.children);

    if (active) {
      hide();
    } else {
      show();
    }

    setActive(!active);
  };

  const show = () => {
    gsap.to(background.current, { scaleY: 1, opacity: 0.94, duration: 0.5, ease: 'power4.out' });
    gsap.to(navigation.current.children, {
      opacity: 1,
      delay: 0.1,
      y: 0,
      duration: 1,
      stagger: 0.06,
      ease: 'power4.out',
    });
  };

  const hide = () => {
    gsap.to(background.current, { scaleY: 0, opacity: 0.2, duration: 1, ease: 'power4.inOut' });
    gsap.to(navigation.current.children, { opacity: 0, duration: 0.5, y: -50, stagger: 0.04, ease: 'power2.inOut' });
  };

  /*
   * visuals
   */

  return (
    <>
      <header ref={header} className={style.header}>
        <div className={style.logo}>REGASHI</div>

        <div className={style.right}>
          <StandardButton>{t('wallet')}</StandardButton>
          <StandardButton>{t('mint')}</StandardButton>
          <LanguageSelect />
          <MenuButton onClick={menuButtonHandler} active={active} />
        </div>
      </header>

      <div className={style.panel}>
        <div ref={background} className={style.background} />
        <nav ref={navigation} className={`${style.navigation} ${active ? style.active : ''}`}>
          <BigButton index={1} onClick={() => bigButtonHandler(0)} label={t('intro')} />
          <BigButton index={2} onClick={() => bigButtonHandler(1)} label={t('about')} />
          <BigButton index={3} onClick={() => bigButtonHandler(2)} label={t('team')} />
          {/* <BigButton index={4} onClick={() => bigButtonHandler(3)} label="Timeline" /> */}
          <BigButton index={4} onClick={() => bigButtonHandler(3)} label={t('bear')} />
          <BigButton index={5} onClick={() => bigButtonHandler(4)} label={t('collection')} />
          <BigButton index={6} onClick={() => bigButtonHandler(5)} label={t('community')} />
          {/* <BigButton index={7} onClick={() => bigButtonHandler(6)} label="Soon" /> */}
        </nav>
      </div>
    </>
  );
}
