import { MeshPortalMaterial } from '@react-three/drei';

export function Portal({ children, worldUnits = false }) {
  /*
   * properties
   */

  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
      <mesh>
        <planeGeometry args={[1, 1, 1, 1]} />
        <MeshPortalMaterial worldUnits={worldUnits}>{children}</MeshPortalMaterial>
      </mesh>
    </>
  );
}
