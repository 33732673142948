import { useEffect, useRef } from 'react';
import { useMouse } from '../../../stores/useMouse';
import { useKTX2, useTexture } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useMaps } from '../../../stores/useMaps';
import { useMap } from '../../core/useMap';

export function Picture({ map, displacementMap }) {
  /*
   * properties
   */

  const mesh = useRef();

  // const mapTexture = useTexture(map);
  // const mapTexture = useKTX2(map);
  // const displacementMapTexture = useTexture(displacementMap);
  // const displacementMapTexture = useKTX2(displacementMap);

  // const [mapTexture, displacementMapTexture] = useKTX2([map, displacementMap]);


  const mapTexture = useMaps.getState().maps[map];
  const displacementMapTexture = useMaps.getState().maps[displacementMap];

  /*
   * hooks
   */

  useFrame((state) => {
    const x = useMouse.getState().slowPctX;
    const y = useMouse.getState().slowPctY;

    mesh.current.rotation.y = -x * 0.02;
    mesh.current.rotation.x = -y * 0.02;
  });

  /*
   * visuals
   */

  return (
    <>
      <mesh ref={mesh}>
        <planeGeometry args={[1, 1, 20, 20]} />
        <meshMatcapMaterial map={mapTexture} displacementMap={displacementMapTexture} displacementScale={0.2} />
      </mesh>
    </>
  );
}
