import style from './BigButton.module.css';

export function BigButton({ onClick, label, index }) {
  /*
   * properties
   */

  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
      <button className={style.button} onClick={onClick}>
        <div className={style.inner}>
          <div className={style.label1}>{label}</div>
          <div className={style.label2}>{label}</div>
        </div>
        <div className={style.num}>0{index}</div>
      </button>
    </>
  );
}
