import { useEffect, useRef } from 'react';
import style from './Community.module.css';
import { useMainScroll } from '../../../../stores/useMainScroll';

let isVisible = false;

export function Community() {
  /*
   * properties
   */

  const container = useRef();
  const getSectionPct = useMainScroll((state) => state.getSectionPct);

  /*
   * hooks
   */

  useEffect(() => {
    loop();
  }, []);

  const loop = () => {
    let pct = getSectionPct(6);

    setVisible(pct);

    requestAnimationFrame(loop);
  };

  const setVisible = (pct) => {
    // console.log(pct);
    const visible = pct > 0 && pct <= 1;
    if (isVisible !== visible) {
      isVisible = visible;

      container.current.style.visibility = isVisible ? 'visible' : 'hidden';
    }
  };

  /*
   * visuals
   */

  return (
    <>
      <div ref={container} className={style.container}>
        <h1 className={style.header}>[COMMUNITY PLACEHOLDER]</h1>
      </div>
    </>
  );
}
