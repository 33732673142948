import { useEffect } from 'react';
import style from './Grid.module.css';
import { useRef } from 'react';
import gsap from 'gsap';
import { useSections } from '../../../stores/useSections';

export function Grid() {
  /*
   * properties
   */

  const container = useRef();
  const loaded = useSections((state) => state.loaded);

  /*
   * hooks
   */

  useEffect(() => {
    if (loaded) {
      show();
    }
  }, [loaded]);

  const show = () => {
    gsap.set(container.current, { scale: 1.2 });
    gsap.to(container.current, { opacity: 1, scale: 1, duration: 2.4, ease: 'power4.out' });
  };

  /*
   * visuals
   */

  return (
    <>
      <div ref={container} className={style.grid}>
        <div className={style.lineH1} />
        <div className={style.lineH2} />
        <div className={style.lineH3} />
        <div className={style.lineH4} />

        <div className={style.lineV1} />
        <div className={style.lineV2} />

        <Icon index={1} />
        <Icon index={2} />
        <Icon index={3} />
        <Icon index={4} />
      </div>
    </>
  );
}

function Icon({ index }) {
  return (
    <>
      <div className={style['icon' + index]}>
        <div className={style.bar1} />
        <div className={style.bar2} />
      </div>
    </>
  );
}
