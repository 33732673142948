import { Route, Routes, useLocation } from 'react-router-dom';
import style from './App2D.module.css';
import { Home } from './pages/home/Home';
import { useEffect } from 'react';
import { Header } from './component/header/Header';
import { FixedFooter } from './component/footer/FixedFooter';
import { ProgressBar } from './component/scroll/ProgressBar';
import { MainScroll } from './component/scroll/MainScroll';
import { ScrollIndicator } from './component/scroll/ScrollIndicator';
import { Grid } from './component/grid/Grid';
import { useSections } from '../stores/useSections';

export function App2D() {
  /*
   * properties
   */

  const loaded = useSections((state) => state.loaded);

  /*
   * hooks
   */

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const resizeHandler = () => {
    let scale = window.innerWidth / 1440;
    let size = 0.5 + scale * 0.5;

    var r = document.querySelector(':root');
    r.style.setProperty('--size', size);
    r.style.setProperty('--scale', scale);
  };

  /*
   * visuals
   */

  return (
    <>
      <div className={style.bg} />
      <MainScroll>
        <Header />
        <Grid />
        <ProgressBar />

        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>

        <ScrollIndicator />
        <FixedFooter />
      </MainScroll>
    </>
  );
}
