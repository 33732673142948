import { useRef } from 'react';
import { Hero } from './Hero';
import { useMainScroll } from '../../../../stores/useMainScroll';
import { useFrame } from '@react-three/fiber';

export function About() {
  /*
   * properties
   */

  const group = useRef();
  const getSectionPct = useMainScroll((state) => state.getSectionPct);

  /*
   * hooks
   */

  useFrame(() => {
    let pct = getSectionPct(2);

    const active = pct > 0 && pct < 1;

    if (group.current.visible != active) {
      // console.log('SET TO ' + active);
      group.current.visible = active;
    }
  });

  /*
   * visuals
   */

  return (
    <>
      <group ref={group} visible={false}>
        <Hero />
      </group>
    </>
  );
}
