import { useFrame } from '@react-three/fiber';
import { Intro } from './intro/Intro';
import { useMainScroll } from '../../../stores/useMainScroll';
import { useRef } from 'react';
import { Team } from './team/Team';
import { About } from './about/About';

export function Home() {
  /*
   * properties
   */

  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
      <Intro />
      <About />
      <Team />
    </>
  );
}
