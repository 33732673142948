import { useEffect, useRef } from 'react';
import { useMainScroll } from '../../../stores/useMainScroll';
import style from './ProgressBar.module.css';
import gsap from 'gsap';
import { useSections } from '../../../stores/useSections';

export function ProgressBar() {
  /*
   * properties
   */

  const container = useRef();
  const pct = useMainScroll((state) => state.pct);
  const loaded = useSections((state) => state.loaded);

  /*
   * hooks
   */

  useEffect(() => {
    if (loaded) {
      show();
    }
  }, [loaded]);

  const show = () => {
    gsap.set(container.current, { x: 15 });
    gsap.to(container.current, { delay: 1, duration: 2, opacity: 1, x: 0, ease: 'power4.inOut' });
  };

  /*
   * visuals
   */

  return (
    <>
      <div ref={container} className={style.bar}>
        <div className={style.inner} style={{ height: `${pct * 100}%` }} />
      </div>
    </>
  );
}
