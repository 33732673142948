import style from './MenuButton.module.css';

export function MenuButton({ onClick, active }) {
  /*
   * properties
   */

  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
      <button aria-label="menu" className={`${style.button} ${active ? style.active : ''}`} onClick={onClick}>
        <div className={`${style.bar} ${style.bar1}`} />
        <div className={`${style.bar} ${style.bar2}`} />
      </button>
    </>
  );
}
