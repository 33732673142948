import { useEffect } from 'react';
import { useMouse } from '../stores/useMouse';

export function MouseController() {
  /*
   * properties
   */
  const { setMousePosition, calculateSlowMousePosition, setMouseMaximums, initMousePosition, setTouch } = useMouse();

  let lastUpdate;

  /*
   * hooks
   */

  // start
  useEffect(() => {
    window.addEventListener('touchstart', touchHandler);
    window.addEventListener('resize', resizeHandler);
    resizeHandler();
    window.addEventListener('mousemove', handleMouseMove);

    const intervalId = setInterval(loop, 1000 / 60);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener('touchstart', touchHandler);
      window.removeEventListener('resize', resizeHandler);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const touchHandler = () => {
    console.log('touchHandler');
    setTouch();
    window.removeEventListener('touchstart', touchHandler);
  };

  // resize
  const resizeHandler = () => {
    const xMax = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    const yMax = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

    setMouseMaximums(xMax, yMax);
  };

  // mouse move
  const handleMouseMove = (event) => {
    const x = event.clientX;
    const y = event.clientY;

    if (!useMouse.getState().inited) {
      initMousePosition(window.innerWidth * 0.5, window.innerHeight * 0.5);
    } else {
      setMousePosition(x, y);
    }
  };

  const loop = (timestamp) => {
    if (useMouse.getState().inited) {
      var now = Date.now();
      var delta = now - lastUpdate;
      lastUpdate = now;

      if (delta) {
        calculateSlowMousePosition(delta);
      }
    }
  };
}
