import { useKTX2 } from '@react-three/drei';
import { useMaps } from '../stores/useMaps';

export function Materials() {
  /*
   * properties
   */

  const maps = useKTX2([
    '/textures/intro/hero.ktx2',
    '/textures/intro/hero_depth.ktx2',

    '/textures/about/about.ktx2',
    '/textures/about/about_depth.ktx2',

    '/textures/team/team1.ktx2',
    '/textures/team/team1_depth.ktx2',
    '/textures/team/team2.ktx2',
    '/textures/team/team2_depth.ktx2',
    '/textures/team/team3.ktx2',
    '/textures/team/team3_depth.ktx2',
    '/textures/team/team4.ktx2',
    '/textures/team/team4_depth.ktx2',
    '/textures/team/team5.ktx2',
    '/textures/team/team5_depth.ktx2',
  ]);

  useMaps.getState().init(maps);

  /*
   * hooks
   */

  return <></>;
}
