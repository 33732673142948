import { useEffect, useRef } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { Picture } from '../../../components/picture/Picture';
import gsap from 'gsap';
import { Portal } from '../../../components/picture/Portal';
import { useMainScroll } from '../../../../stores/useMainScroll';
import { useTexture } from '@react-three/drei';
import { useSections } from '../../../../stores/useSections';

let isActive = false;

export function Hero() {
  /*
   * properties
   */

  const portalContainer = useRef();
  const pictureContainer = useRef();

  const getSectionPct = useMainScroll((state) => state.getSectionPct);
  const loaded = useSections((state) => state.loaded);

  const { viewport } = useThree();
  const size = Math.max(viewport.width, viewport.height);

  /*
   * hooks
   */

  useEffect(() => {
    if (loaded) {
      show();
    }
  }, [loaded]);

  const show = () => {
    portalContainer.current.visible = true;

    // portal
    let scale = 0.3;
    gsap.set(portalContainer.current.scale, { x: scale, y: scale, z: scale });
    gsap.set(portalContainer.current.rotation, { x: 1.6, y: 0 });

    gsap.to(portalContainer.current.rotation, { x: 0, y: 0, duration: 2, ease: 'power2.inOut' });
    scale = 1;
    gsap.to(portalContainer.current.scale, { x: scale, y: scale, z: scale, duration: 2, ease: 'power1.inOut' });

    // picture
    gsap.set(pictureContainer.current.rotation, { x: 0.5, y: 0 });
    gsap.to(pictureContainer.current.rotation, {
      x: 0,
      y: 0,
      duration: 2,
      ease: 'power2.inOut',
      onComplete: () => {
        isActive = true;
      },
    });
  };

  useFrame(() => {
    if (isActive) {
      let pct = getSectionPct(1);

      pct = Math.pow(pct, 4);

      if (pct >= 0 && pct < 1) {
        const scale = 1 - pct * 0.7;
        const rotation = pct * 1.57;
        portalContainer.current.scale.set(scale, scale, 1);
        portalContainer.current.rotation.x = rotation;

        pictureContainer.current.rotation.x = -rotation * 0.25;

        portalContainer.current.visible = pct < 1;
      }
    }
  });

  /*
   * visuals
   */

  return (
    <>
      <group scale={size}>
        <group ref={portalContainer} visible={false}>
          <Portal worldUnits={true}>
            <group scale={size}>
              <group ref={pictureContainer}>
                <Picture map="home" displacementMap="homeDepth" />
              </group>
            </group>
          </Portal>
        </group>
      </group>
    </>
  );
}
