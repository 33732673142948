import { useEffect, useRef, useState } from 'react';
import style from './MainScroll.module.css';
import { useGesture } from '@use-gesture/react';
import { useMainScroll } from '../../../stores/useMainScroll';
import { useScript } from '@uidotdev/usehooks';

export function MainScroll({ children }) {
  // export function SectionScroll() {

  /*
   * properties
   */

  const container = useRef();
  const setPct = useMainScroll((state) => state.setPct);
  const sections = useMainScroll((state) => state.sections);
  const setActiveSection = useMainScroll((state) => state.setActiveSection);
  const scrollToSection = useMainScroll((state) => state.scrollToSection);
  const resetScrollToSection = useMainScroll((state) => state.resetScrollToSection);

  const [active, setActive] = useState(false);

  useGesture(
    {
      onScroll: (state) => {
        const element = container.current;

        const pct = element.scrollTop / (element.scrollHeight - element.clientHeight);
        setPct(pct);

        const activeSection = Math.min(sections, Math.floor(pct * sections) + 1);
        const currentActiveSection = useMainScroll.getState().activeSection;
        if (currentActiveSection !== activeSection) {
          setActiveSection(activeSection);
        }

        // console.log(element.scrollTop);

        resetScrollToSection();
      },
    },
    {
      target: container,
    }
  );

  /*
   * hooks
   */

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setActive(true);
    }, [3000]);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    if (scrollToSection >= 0) {
      const element = container.current;
      const slideSpace = (element.scrollHeight - element.clientHeight) / 7;

      container.current.scrollTo({
        top: scrollToSection === 0 ? 0 : slideSpace * (scrollToSection + 0.5),
        behavior: 'smooth',
      });
    }
  }, [scrollToSection]);

  /*
   * visuals
   */

  return (
    <>
      <div ref={container} className={`${style.scrollContainer} ${active ? style.active : ''}`}>
        <div className={style.content}>{children}</div>
        <div className={style.inner} />
      </div>
    </>
  );
}
