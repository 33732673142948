import { Fragment, useEffect, useRef } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { useMainScroll } from '../../../../stores/useMainScroll';
import { Member } from './Member';
import { MathUtils } from 'three';

const amount = 5;
const angleGap = 0.5;
const yGap = 0.5;

let scale = 1;

export function Carousel() {
  /*
   * properties
   */

  const getSectionPct = useMainScroll((state) => state.getSectionPct);
  const container = useRef();

  /*
   * hooks
   */

  useEffect(() => {
    resizeHandler();
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const resizeHandler = () => {
    const ratio = Math.max(0, window.innerWidth / window.innerHeight);
    scale = 1 + 0.15 * ratio;

    container.current.scale.set(scale, scale, scale);
  };

  useFrame(() => {
    let pct = getSectionPct(3);

    if (pct > 0 && pct < 1) {
      const movePct = MathUtils.mapLinear(pct, 0.1, 0.9, 0, 1);

      container.current.rotation.y = -movePct * (amount - 1) * angleGap;
      container.current.position.y = -movePct * (amount - 1) * yGap * scale;
    }
  });

  /*
   * visuals
   */

  return (
    <>
      <group ref={container}>
        {[...Array(amount)].map((value, index) => (
          <Fragment key={index}>
            <Member index={index} angleGap={angleGap} yGap={yGap} amount={amount} />
          </Fragment>
        ))}
      </group>
    </>
  );
}
