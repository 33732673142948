import { useEffect, useRef } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { Picture } from '../../../components/picture/Picture';
import gsap from 'gsap';
import { Portal } from '../../../components/picture/Portal';
import { useMainScroll } from '../../../../stores/useMainScroll';
import { useTexture } from '@react-three/drei';
import { useSections } from '../../../../stores/useSections';
import { MathUtils } from 'three';

let isActive = false;

export function Hero() {
  /*
   * properties
   */

  const portalContainer = useRef();
  const pictureContainer = useRef();

  const getSectionPct = useMainScroll((state) => state.getSectionPct);
  const loaded = useSections((state) => state.loaded);

  const { viewport } = useThree();
  const size = Math.max(viewport.width, viewport.height);

  /*
   * hooks
   */

  useFrame(() => {
    // if (isActive) {
    let pct = getSectionPct(2);

    // pct = Math.pow(pct, 4);

    if (pct >= 0 && pct < 1) {
      const start = MathUtils.mapLinear(pct, 0, 0.2, 0, 1);
      const end = MathUtils.mapLinear(pct, 0.8, 1, 1, 0);
      let num = Math.min(start, end, 1);

      num = Math.pow(num, 0.4);

      portalContainer.current.scale.set(num, num, num);
      let scale = 0.5 + num * 0.6;
      pictureContainer.current.scale.set(scale, scale, scale);

      pictureContainer.current.rotation.y = -0.2 + pct * 0.4;
    }
    // }
  });

  /*
   * visuals
   */

  return (
    <>
      <group scale={size}>
        <group ref={portalContainer} visible={true}>
          <Portal worldUnits={true}>
            <group scale={size}>
              <group ref={pictureContainer}>
                <Picture map="about" displacementMap="aboutDepth" />
              </group>
            </group>
          </Portal>
        </group>
      </group>
    </>
  );
}
